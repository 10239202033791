import { render, staticRenderFns } from "./DashboardPPALab.vue?vue&type=template&id=7401f017&scoped=true&"
import script from "./DashboardPPALab.vue?vue&type=script&lang=js&"
export * from "./DashboardPPALab.vue?vue&type=script&lang=js&"
import style0 from "./DashboardPPALab.vue?vue&type=style&index=0&id=7401f017&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7401f017",
  null
  
)

export default component.exports